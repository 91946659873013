body {
  margin: 0;
  /* overflow-y: inherit; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.linebreak {
  white-space: pre-line;
}

@font-face {
  font-family: "BankGothic";
  src: local("BankGothic"),
    url("./fonts/BankGothic\ Bold.ttf") format("truetype");
  font-weight: '400';
}
 .siteFont {
  font-family: BankGothic;
  color: #0f0;
  /* font-weight: lighter; */
 
  
}
.title {
  
  position: relative;
  align-self: center;
  width: 100%;
  text-align: center;
  margin: 0;
}

#box {
  align-items: center;
  text-align: center;
  width: 100%;
  align-self: center;
  
}
a {
  color: #0f0;
}

#Matrix {
  opacity: 1;
  transition: opacity 0.5s linear;
  position: absolute;
}

.hidden {
  opacity: 0;
}
p {
  width: 100%;
  padding: 10;
  white-space: pre-line;
  overflow: hidden;
}


/* button */
button ,
button::after {
 padding: 16px 20px;
 font-size: 18px;
 background: #333;
  /* outline: 2px solid #0f0;
  outline-color: #0f0; */
  box-shadow: none;

 border-color: #0f0;
 color: #0f0;
 /* letter-spacing: 3px; */
 line-height: 1;

 outline: transparent;
 position: relative;
}
button:focus {
  outline:none !important;
}
button::after {
 --slice-0: inset(50% 50% 50% 50%);
 --slice-1: inset(80% -6px 0 0);
 --slice-2: inset(50% -6px 30% 0);
 --slice-3: inset(10% -6px 85% 0);
 --slice-4: inset(40% -6px 43% 0);
 --slice-5: inset(80% -6px 5% 0);
 content: "HOVER ME";
 display: block;
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background: #333;
 text-shadow: -3px -3px 0px #f8f005, 3px 3px 0px #00e6f6;
 clip-path: var(--slice-0);
}

button:hover::after {
 animation: 1s glitch;
 animation-timing-function: steps(2, end);
}

@keyframes glitch {
 0% {
  clip-path: var(--slice-1);
  transform: translate(-20px, -10px);
 }

 10% {
  clip-path: var(--slice-3);
  transform: translate(10px, 10px);
 }

 20% {
  clip-path: var(--slice-1);
  transform: translate(-10px, 10px);
 }

 30% {
  clip-path: var(--slice-3);
  transform: translate(0px, 5px);
 }

 40% {
  clip-path: var(--slice-2);
  transform: translate(-5px, 0px);
 }

 50% {
  clip-path: var(--slice-3);
  transform: translate(5px, 0px);
 }

 60% {
  clip-path: var(--slice-4);
  transform: translate(5px, 10px);
 }

 70% {
  clip-path: var(--slice-2);
  transform: translate(-10px, 10px);
 }

 80% {
  clip-path: var(--slice-5);
  transform: translate(20px, -10px);
 }

 90% {
  clip-path: var(--slice-1);
  transform: translate(-10px, 0px);
 }

 100% {
  clip-path: var(--slice-1);
  transform: translate(0);
 }
}
 