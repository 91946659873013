.box {
  height: 100%;
  width: 100%;

}

.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(2); }

.font-face-gm {
  font-family: "GoldmanBold";
 }
 html {
  background: black;
  height: 100%;
  overflow-y: auto; 
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}